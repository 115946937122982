import React, { useEffect, useState } from "react";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function JitsiMeet(props) {
  const { product, type } = props;
  const [isMeetingTime, setIsMeetingTime] = useState(false);
  const [jwtToken, setJwtToken] = useState("");
  const [meetingUrl, setMeetingUrl] = useState("");
  const [roomName, setRoomName] = useState(
    product.meta_data
      ?.find((meta) => meta.meta_key === "meet_jitsi")
      ?.meta_value.replaceAll(" ", "")
  );
  const user = useSelector((state) => state.user.user);
  const [date, setDate] = useState(moment().format("HH:mm:ss"));

  const navigate = useNavigate();

  useEffect(() => {
    // console.log("product", product);
    const fetchMeetingData = async () => {
      try {
        if (product) {
          let startTime, endTime, eventDate;

          if (type === "event") {
            // Para eventos, obtenemos los datos de meta_data
            const startTimeMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_starttime"
            );
            const endTimeMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_endtime"
            );
            const eventDateMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_date"
            );

            if (startTimeMeta && endTimeMeta && eventDateMeta) {
              startTime = startTimeMeta.meta_value;
              endTime = endTimeMeta.meta_value;
              eventDate = eventDateMeta.meta_value.split(" ")[0];
            }
          } else if (type === "appointment") {
            console.log("Appointment");
            console.log("product", product);

            if (product && product.start_time) {
              startTime = product.start_time;

              // Verificar si 'service' y 'meta_data' están definidos
              if (product.service && product.service.meta_data) {
                const durationMeta = product.service.meta_data.find(
                  (meta) => meta.meta_key === "service_duration_time"
                );

                if (durationMeta) {
                  const duration = durationMeta.meta_value;

                  // Verificar que el formato de duración es correcto para moment
                  endTime = moment(startTime)
                    .add(moment.duration(duration))
                    .format("HH:mm:ss");
                }
              }

              // Obtener la fecha del evento a partir de 'start_time'
              eventDate = product.start_time.split("T")[0];
            } else {
              console.error(
                "El objeto 'product' o 'start_time' está indefinido"
              );
            }
          }

          console.log("starttime", startTime);
          console.log("endTime", endTime);
          console.log("eventDate", eventDate);

          if (startTime && endTime && eventDate) {
            console.log("startTime && endTime && eventDate");
            const eventStartDateTime = moment(
              `${eventDate} ${startTime}`,
              "YYYY-MM-DD HH:mm:ss"
            );
            const eventEndDateTime = moment(
              `${eventDate} ${endTime}`,
              "YYYY-MM-DD HH:mm:ss"
            );
            const now = moment();

            if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
              const minutesToStart = moment
                .duration(eventStartDateTime.diff(now))
                .asMinutes();
              const minutesToEnd = moment
                .duration(eventEndDateTime.diff(now))
                .asMinutes();

              // if (jitsiName && minutesToStart <= 30 && minutesToEnd >= 0) {
              setIsMeetingTime(true);
              // if (!jwtToken) {
              //   getJWT();
              // }
              // }
            } else {
              console.log("Invalid startTime or endTime");
            }
          }

          if (!jwtToken) {
            getJWT();
          }
        }
      } catch (error) {
        console.error("Error fetching meeting data:", error);
      }
    };

    fetchMeetingData();
  }, [product, type, jwtToken]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const getJWT = async () => {
    const APIURL = `/api/jitsi-meet/accessAuth`;
    console.log("product AAAAAAAA", product);
    console.log("type", type);
    const eventData = {
      id: type === "event" ? product.id : product.id,
      owner_id: type === "event" ? product.owner_id : product.service.id,
    };

    try {
      const res = await axiosWithAuth().post(APIURL, {
        roomName: product.meta_data?.find(
          (meta) => meta.meta_key === "meet_jitsi"
        )?.meta_value,
        user: {
          id: user.id,
          name: user.user_login,
          email: user.email,
        },
        eventData: eventData,
        typeEvent: type === "event" ? "event" : "appointment",
      });
      setJwtToken(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = async () => {
    if (roomName) {
      navigate(`/jitsi-meet/${roomName}`, {
        state: { jwtToken, roomName, type: "moderator" },
      });
    } else {
      try {
        const res = await axiosWithAuth().post(
          `${process.env.REACT_APP_BE_API_URL}/api/jitsi-meet/meetings`,
          {
            serviceName: product.service.name,
            date: product.start_time,
            idAppointment: product.id,
          }
        );
        console.log("res", res);
      } catch (error) {
        console.error("Error al crear la reunión:", error);
      }
    }
  };

  return (
    <div>
      <button onClick={handleButtonClick}>
        {roomName ? "Join Meeting" : "Create Meeting"}
      </button>
    </div>
  );
}

// import axiosWithAuth from "../../../../utils/axiosWithAuth";
// import { Button } from "antd";
// import moment from "moment";

// export default function JitsiMeet(props) {
//   const { appointment } = props;

//   const [date, setDate] = useState(moment().format("HH:mm:ss"));
//   const [meetingUrl, setMeetingUrl] = useState("");

//   useEffect(() => {
//     if (appointment && appointment.meta_data) {
//       const jitsiMeta = appointment.meta_data.find(
//         (meta) => meta.meta_key === "meet_jitsi"
//       );
//       if (jitsiMeta) {
//         setMeetingUrl(jitsiMeta.meta_value);
//       }
//     }
//   }, [appointment]);

//   const createMeeting = async () => {
//     try {
//       const res = await axiosWithAuth().post(
//         `${process.env.REACT_APP_BE_API_URL}/api/jitsi-meet/meetings`,
//         {
//           roomName:
//             appointment.service.name.replaceAll(" ", "") + "-" + appointment.id,
//           date: date,
//           typeMeet: "service",
//           idAppointment: appointment.id,
//         }
//       );
//       console.log("res", res);
//       setMeetingUrl(res.data.meetingData.url);
//     } catch (error) {
//       console.error("Error al crear la reunión:", error);
//     }
//   };

//   const handleButtonClick = () => {
//     if (meetingUrl) {
//       window.open(meetingUrl, "_blank", "noopener,noreferrer");
//     } else {
//       createMeeting();
//     }
//   };

//   if (!appointment) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <div>
//         <button onClick={handleButtonClick}>
//           {meetingUrl ? "Join Meeting" : "Create Meeting"}
//         </button>
//       </div>
//     </div>
//   );
// }
